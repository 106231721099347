import React from 'react'

import styled from 'styled-components'

const Rats = styled.div`

font-family: europa, sans-serif;
font-weight: 300;
font-style: normal;
font-size: 16px;
letter-spacing: 2px;
line-height: 1.4em;


.rates-column {
    display: flex;
    grid-template-columns: 30 40 30;
    justify-content: center;
    
    
}
.rates-boxs {
padding: 44px;
display: 30%;
font-size: 30px;
font-weight: normal;
}

.rates-column h1 {
    font-size: 54px;
}

.rates-container {
  width: 100%;
  height: 100vh;
  background-color: thistle;

}

.header-img {
  height: 60vh;
  width: 100%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100%;
  background-position: center;
  background-image: url("https://images.squarespace-cdn.com/content/v1/555f7716e4b0c32dcc3cfe69/1524946374169-BIT9G88MDTHF5OHDPVP9/ke17ZwdGBToddI8pDm48kDHPSfPanjkWqhH6pl6g5ph7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5dNb1QJw9casjKdtTg1_-y4jz4ptJBmI9gQmbjSQnNGng/IMG_3491.jpg?format=2500w");
}

.intro {
    font-size: 22px;
    padding-left: 10%;
    padding-right: 10%;

}

.packages {
    margin-top:5vh;
    text-decoration: underline;
}

.buyOut {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .rates-column {
        display: block

    }

    .header-image {
    
            margin-top: -130px;
    
    }

   
}
`

export default function Rates() {
    return (
        <Rats>
            <div className="header-img"></div>



            <h1 style={{ marginTop: "5vh" }}>5 Night Luxury Accommodations / 6 Day</h1>
            <div className="intro">
                <ul>
                    <li>In-Country Airfare & Hotel Transfers </li>
                    <li>Farm to Table Meals</li>
                    <li>Full day guided tour to Corcovado National Park</li>
                    <li>Primary Rainforest Canopy Zip Line</li>
                    <li>Full Day Guided Boat Fish Whale Dolphin Watch</li>
                    <li>Beach Day Snorkel or Mangrove Tour</li>
                </ul>

            </div>
            <div className="rates-column">
                <div className="rates-boxs"><h1>
                    Superior</h1>
                    <p>1 Pax $2,959</p>
                    <p>2 Pax $2,602</p>
                    <p>Family Four Pax</p>
                    <p>$6,564</p>

                </div>
                <div className="rates-boxs"><h1>
                    Deluxe
                </h1>
                    <p>1 Pax $3,256</p>
                    <p>2 Pax $2,899</p>
                    <p>3 Pax $2,779</p>
                    <p>4 Pax $2,720</p>
                    <p>Family Four Pax </p>
                    <p>$7,358 </p>
                </div>

                <div className="rates-boxs">
                    <h1>Sunset</h1>
                    <p>1 Pax $3,515</p>
                    <p>2 Pax $3,157</p>
                    <p>3 Pax $3,038</p>
                    <p>4 Pax $2,978</p>
                    <p>Family Four Pax  </p>
                    <p>$7,874</p>


                </div>

            </div>
            <p>
                *Additional Children 15 and Under $680</p>

            <div className="rates-column">

                <div
                    className="rates-boxs">
                    <h1>
                        Luxury Beach House
                    </h1>
                    <p>
                        4 Pax $2,936 per person</p>
                    <p>
                        6 Pax $2,406 per person</p>
                </div>

            </div>
            <div className="rates-column">
                <div
                    className="rates-boxs">
                    <h1>
                        **Hotel Buyout
                    </h1>
                    <div className="buyOut">
                        <h4>**Luxury Group includes all First and Business Class Seats and the entire hotel**</h4>
                        <h2 ><u>32 person</u></h2>
                        <p>2 couples per Villa, 1 Couple per Superior</p>
                        <p>$2,631 Per Pax</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>28 person</u></h2>
                        <p>2 couples per Villa, 1 Person per Superior</p>
                        <p>$2,663 Per Pax</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>20 person</u></h2>
                        <p>
                            $5,385 per person</p>
                        <p>
                            Total $107,700</p>
                    </div>

                </div>
            </div>

            {/* THIRD PAX  */}

            <h1 style={{ marginTop: "5vh" }}>Eight Night Luxury Accommodations / 9 Day </h1>
            <div className="intro">
                <p>This is a phenomenal way to vacation in Costa Rica providing a perfect blend of the Central Valley, Rainforest, Beaches, Seclusion and Vibrance.</p>
                <p>The package has the same itinerary as the 5 Night Luxury Accomodations  with three additional nights. The first night at a gorgeous coffee plantation above San Jose and the last two at a fun, beautiful hotel in Manuel Antonio. </p>
            </div>
            <div className="rates-column">
                <div className="rates-boxs"><h1>
                    Superior</h1>
                    <p>1 Pax $4,048</p>
                    <p>2 Pax $3,121</p>
                    <p>Family Four Pax</p>
                    <p>$7,269</p>

                </div>
                <div className="rates-boxs"><h1>
                    Deluxe
                </h1>
                    <p>1 Pax $4,345</p>
                    <p>2 Pax $3,418</p>
                    <p>3 Pax $3,428</p>
                    <p>4 Pax $3,187</p>
                    <p>Family Four Pax</p>
                    <p>$8,063</p>
                </div>

                <div className="rates-boxs">
                    <h1>Sunset</h1>
                    <p>1 Pax $3,604</p>
                    <p>2 Pax $3,677</p>
                    <p>3 Pax $3,614</p>
                    <p>4 Pax $3,391</p>
                    <p>Family Four Pax </p>
                    <p>$8,579</p>


                </div>

            </div>
            <p>
                *Additional Children 15 and Under $680</p>

            <div className="rates-column">

                <div
                    className="rates-boxs">
                    <h1>
                        Luxury Beach House
                    </h1>
                    <p>
                        4 Pax $3,469 per person</p>
                    <p>
                        6 Pax $4,166 per person</p>
                </div>

            </div>
            <div className="rates-column">
                <div
                    className="rates-boxs">
                    <h1>
                        **Hotel Buyout
                    </h1>
                    <div className="buyOut">
                        <h4>**Luxury Group includes all First and Business Class Seats and the entire hotel**</h4>
                        <h2 ><u>32 person</u></h2>
                        <p>2 couples per Villa, 1 Couple per Superior</p>
                        <p>$2,631 Per Pax</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>28 person</u></h2>
                        <p>2 couples per Villa, 1 Person per Superior</p>
                        <p>$2,663 Per Pax</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>20 person</u></h2>
                        <p>
                            $5,385 per person</p>
                        <p>
                            Total $107,700</p>
                    </div>

                </div>
            </div>


            <h1 style={{ marginTop: "5vh" }}>Seven Night / Eight Day Accommodations</h1>
            <div className="intro">
                <ul>

                    <li>International & Domestic Airfare</li>
                    <li>All Meals</li>

                    <li>Full day guided tour to Corcovado National Park</li>
                    <li>Full Day guided tour to Caño Island</li>
                </ul>

            </div>
            <div className="rates-column">
                <div className="rates-boxs"><h1>
                    Superior</h1>
                    <p>
                        $5,748 per person</p>

                </div>
                <div className="rates-boxs"><h1>
                    Deluxe
                </h1>
                    <p>
                        $5,433 per person
                    </p>
                </div>

                <div className="rates-boxs">
                    <h1>Sunset</h1>
                    <p>$5,063 per person</p>

                </div>

            </div>
            <p>
                $4,433 per child 12 and under</p>
            <h1>**FIRST class seat $600 upgrade fee
            </h1>
            <h1>***Business class seat $400 upgrade fee</h1>
            <div className="rates-column">

                <div
                    className="rates-boxs">
                    <h1>
                        Beach House
                    </h1>
                    <p>
                        Master: $5,433 per person</p>
                    <p>
                        Apartment $5,063 per person</p>
                </div>

            </div>
            <div className="rates-column">
                <div
                    className="rates-boxs">
                    <h1>
                        Hotel Buy Out
                    </h1>
                    <div className="buyOut">
                        <h4>**Luxury Group includes all First and Business Class Seats and the entire hotel**</h4>
                        <h2 ><u>34 person</u></h2>
                        <p>
                            $5,270 per person</p>
                        <p>
                            Total $200,282</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>38 person</u></h2>
                        <p>
                            $5,382 per person</p>
                        <p>
                            Total $204,534</p>
                    </div>
                    <div className="buyOut">
                        <h2 ><u>20 person</u></h2>
                        <p>
                            $5,385 per person</p>
                        <p>
                            Total $107,700</p>
                    </div>

                </div>
            </div>


        </Rats>
    )
}
